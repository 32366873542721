@import url(//fonts.googleapis.com/css?family=Nunito);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-size: 16px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

/* Misc styling */
.black {
  color: #485785;
}

.red {
  color: red;
}

.noUnderline {
  text-decoration: none;
}

.marginBottom {
  margin-bottom: 1rem;
}

.greyText {
  color: #485785;
  font-weight: normal;
}

/* Image styling */
.App-logo {
  height: 1.95rem;
  pointer-events: none;
  position: relative;
  top: -1px;
}

.friendsLogo {
  height: 10rem;
}

/* ------------------------------------------------ */

/* Header styles */
.App-header {
  background-color: #f0f5fa;
  height: 6rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: white;
}

.App-header h1 {
  color: #0672fa;
  padding: 0;
  margin: 0;
  font-size: 2.65rem;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topHeader {
  display: flex;
  flex-direction: row;
  height: 3rem;
  padding-top: 1rem;
}

.bottomHeader {
  display: flex;
  flex-direction: row;
  height: 1rem;
  color: black;
  font-size: 0.7rem;
}

.navbar-light,
.navbar-nav,
.nav-link {
  background-color: #f0f5fa;
}

.navDropdown {
  background-color: #f0f5fa;
}

.menuLinksLeft {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.menuLinksLeft ul {
  margin: 0;
  padding: 0;
}

.menuLinksRight {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 7rem;
}

.menuLinksLeft li,
.menuLinksRight li {
  list-style-type: none;
  display: inline;
  padding-left: 1rem;
}

.menuLinksLeft a,
.menuLinksRight a {
  text-decoration: none;
  color: #7b8ab8;
}

/* End of header styles -------------------------------------------------- */

/* Body section styles */
.body {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

/* Banner at top of home page */
.banner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #d4e7fa;
  border-radius: 5px;
  width: 100%;
}

.back-image {
  background-image: url(/static/media/friendsLogo.3355daee.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.banner h1 {
  color: #485785;
}

.banner h2 {
  color: #7b8ab8;
}

.bannerLeft {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.bannerLeft h5 {
  color: #7b8ab8;
  font-weight: normal;
}

@media only screen and (max-width: 780px) {
  .bannerLeft {
    width: 100%;
  }

  .bannerLeft h5 {
    color: white;
  }

  .opacity {
    background-color: #000;
    -webkit-filter: opacity(60%);
            filter: opacity(60%);
    padding: 0.5rem;
  }
}
/* End of banner styles ---------------------------------- */

/* "My ward" section of home page */
.myWardSection,
.opportunitiesSection {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  color: #485785;
}

.myWardSectionLeft,
.myWardSectionLeft:focus,
.myWardSectionLeft:hover,
.myWardSectionLeft:link,
.myWardSectionLeft:active {
  text-decoration: none;
  color: inherit;
  height: 4rem;
  border: 1px solid #c4e0f7;
  border-radius: 5px;
  background-color: #f1f6fa;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  width: 49%;
}

.myWardSectionLeft h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.myWardSectionRight,
.myWardSectionRight:focus,
.myWardSectionRight:hover,
.myWardSectionRight:link,
.myWardSectionRight:active {
  text-decoration: none;
  color: inherit;
  height: 4rem;
  border: 1px solid #c4e0f7;
  border-radius: 5px;
  background-color: #f1f6fa;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  width: 49%;
}

.myWardSectionRight h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

/* "Opportunities" section of home page */
.opportunitiesSection {
  width: 100%;
}

@media only screen and (max-width: 780px) {
  .opportunitiesSection p {
    font-size: 0;
  }

  .myWardSectionLeft h5 {
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
  }

  .myWardSectionLeft {
    padding-top: 0.5rem;
  }

  .myWardSectionRight {
    padding-top: 0.5rem;
  }
}

/* Divs that use flexbox "row" */
.rows,
.row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
}

.opportunitiesItems,
.opportunitiesItems:focus,
.opportunitiesItems:hover,
.opportunitiesItems:link,
.opportunitiesItems:active {
  text-decoration: none;
  height: 5rem;
  border: 1px solid #c4e0f7;
  border-radius: 5px;
  background-color: #f1f6fa;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  color: #485785;
  width: 49%;
}

.opportunitiesItemsRight,
.opportunitiesItemsRight:focus,
.opportunitiesItemsRight:hover,
.opportunitiesItemsRight:link,
.opportunitiesItemsRight:active {
  text-decoration: none;
  height: 5rem;
  border: 1px solid #c4e0f7;
  border-radius: 5px;
  background-color: #f1f6fa;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  color: #485785;
}

@media only screen and (max-width: 780px) {
  .opportunitiesItems {
    text-align: center;
    padding-left: 0rem;
  }
}

/* "Wide blocks" are page wide divs in Opportunities section of home page */
.wideBlocks,
.wideBlocks:focus,
.wideBlocks:hover,
.wideBlocks:link,
.wideBlocks:active {
  text-decoration: none;
  height: 5rem;
  border: 1px solid #c4e0f7;
  border-radius: 5px;
  background-color: #f1f6fa;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  color: #485785;
  width: 100%;
}

.opportunitiesItems h5,
.opportunitiesItemsRight h5,
.myWardSectionLeft h5,
.myWardSectionRight h5,
.wideBlocks h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.myWardSection h2,
.opportunitiesSection h2,
.normalPageDiv h2,
.trainingDiv h2 {
  color: #0672fa;
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.trainingDiv,
.normalPageDiv {
  margin-top: 0.5rem;
}

/* "Search jobs" page styles */
.searchFormDiv {
  margin-top: 1rem;
}

.jobsTable {
  margin-top: 2rem;
}
/* -------------------------------------- */

/* Search subcontracts page styles */
.subcontractsFormDiv {
  margin-top: 1rem;
}

/* "Become a CLO" page styling */
.becomeCloFormDiv {
  margin-top: 1rem;
}

/* "Training" page styling */
.trainingDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trainingCourse {
  display: flex;
  flex-direction: column;
}

.courseTitle {
  text-align: center;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-weight: bold;
}

.courseButton {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
/* -------------------------- */

/* Specific Ward page styles */
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.wardData {
  padding: 1rem;
}

.wardData ul {
  margin: 0;
  padding: 0;
}

.wardData li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.wardButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wardButtonItem,
.wardButtonItem:focus,
.wardButtonItem:hover,
.wardButtonItem:link,
.wardButtonItem:active {
  text-decoration: none;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #d4e7fa;
  border-radius: 5px;
  background-color: #f1f6fa;
  text-align: center;
  color: black;
  width: 48%;
}

.redButton,
.redButton:focus,
.redButton:hover,
.redButton:link,
.redButton:active {
  text-decoration: none;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #d4e7fa;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #ed6566;
  width: 48%;
}
/* -------------------------- */

/* Register page styles */
.registerFormDiv {
  margin-top: 1rem;
}

/* --------------------------------------- */

/* Login page styles */
.loginFormDiv {
  margin-top: 1rem;
}

.loginRow {
  display: flex;
  flex-direction: row;
}

.loginLeft {
  padding: 1rem 0rem;
  margin-top: 1rem;
}

.loginRight {
  padding: 1rem 0rem;
  margin-top: 1rem;
}

.otherLoginButtons {
  margin-left: 1rem;
  margin-top: 0.5rem;
}
/* -------------------------------------- */

/* Current Projects page styles */
@media only screen and (max-width: 780px) {
  .projectsRow h2 {
    font-size: 1.5rem;
  }
}

.projectsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.projectsRow h2 {
  margin-left: 1rem;
}

.projectItem {
  display: flex;
}

.projectImageDiv {
  padding: 0.5rem;
}

.projectImage {
  width: 100%;
}

.projectInfo {
  padding: 0.5rem;
}

.projectInfo ul,
li {
  margin: 0;
  padding: 0;
}

.projectInfo li {
  list-style-type: none;
}

.noStyleLink,
.noStyleLink:focus,
.noStyleLink:hover,
.noStyleLink:link,
.noStyleLink:active {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  border: 1px solid #d4e7fa;
  margin-top: 1rem;
  width: 100%;
}

/* ------------------------------------------- */

/* Specific project page styles */
.infoAndImages {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
}

.imagesRow {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.specProjBigImgDiv {
  width: 50%;
  padding-top: 0.5rem;
}

.specProjSmallImagesDiv {
  width: 50%;
  padding: 0rem 0.5rem;
}

.specProjectBigImage {
  width: 100%;
}

.specProjectSmallImage {
  width: 31%;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.specProjectInfo ul,
li {
  margin: 0;
  padding: 0;
}

.specProjectInfo li {
  list-style-type: none;
}

/* --------------------------------------------- */

/* Footer styles */
.footer {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
  margin-left: 7rem;
  color: #7b8ab8;
}

